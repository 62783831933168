// Here you can add other styles
.react-datepicker-wrapper{
display:block !important;
} 
.pointer{
    cursor: pointer;
}
// .border{
//     border: 1px solid;
// }

.switch-label.switch-lg {
    width: 90px;
    height: 30px;
}
.switch-label{
    width: 90px;
}
.switch-label .switch-input:checked ~ .switch-slider::before {
    transform: translateX(60px);
}

.switch-label .switch-slider::after {
   
    right: 7px;

}